<template>
  <div></div>
</template>

<script>
import api from "@/api/api";
import { v4 as uuidv4 } from "uuid"
import cookie from "@/utils/common.js"
import utils from "@/utils/utils.js"
export default {
  data() {
    return {

    };
  },
  mounted() {
    console.log("route", this.$route)
    this.getUserInfo()
  },
  methods: {
    getUserInfo() {
      // if (params.token == 'kkk') {
      //   cookie.setCookie("account", 'chenken')
      //   cookie.setCookie("server", ["cyl_qyyq", "cyl_hytp", "cyl_qyzl", "kjld_gwjn", "kjld_cytp"])
      //   cookie.setCookie("industry_graph", ["cytp_ldc", "cytp_cd", "cytp_yxyx"])
      //   // cookie.setCookie("industry_news", res.industry_news)
      //   cookie.setCookie("permissions", ["80", "84", "88", "93", "94", "95", "98", "90", "87", "89"])
      //   cookie.setCookie("token", "MTY1ODE5ODQwMS40ODY5NzAyOjU4M2JiMjY4NmMxYjE1ZjBmODY2MjE0YmE0YTc5YTc3YzcyMjNiOTM=")
      //   cookie.setCookie("module", ["is_master"])
      //   cookie.setCookie("role", "admin")
      //   this.$router.push("/firstPage")
      // }
      cookie.setCookie("dianlian_token", this.$route.query.token)
      api.getUserInfoByToken(this.$route.query.access_token).then(res => {
        console.log("user_info", res)
        console.log("vip_perm", JSON.stringify(res.vip_perm))
        cookie.setCookie("account", res.user_info.username)
        cookie.setCookie("server", JSON.stringify(res.server))
        cookie.setCookie("vip_perm", JSON.stringify(res.vip_perm))
        cookie.setCookie("industry_graph", res.industry_graph)
        sessionStorage.setItem("industry_graph", res.industry_graph)
        cookie.setCookie("industry_news", res.industry_news)
        cookie.setCookie("permissions", JSON.stringify(res.permissions))
        cookie.setCookie("token", res.token)
        cookie.setCookie("module", res.module)
        cookie.setCookie("role", "admin")
        // this.$router.push(this.getHomeIUrl());
        // window.location.reload()
        // this.$router.push("/index")
        this.$router.push("/firstPage")
      })
    }
  },
};
</script>

<style scoped lang="scss">
</style>